<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${logonBackgroundMap})`}">
    <ics-page-login-header :project-title="projectTitle" :layout="[]" :show-account="false" />
    <div class="find-pass-word-inner">
      <div class="find-pass-word-form">
        <div>
          <el-steps :active="stepActiveIndex" align-center>
            <el-step title="身份验证" />
            <el-step title="完成" />
          </el-steps>
        </div>
        <div v-if="active === 0" class="find-pass-word-content">
          <div class="find-pass-word-content-title">
            <i class="el-icon-info" style="color: #1989fa !important;font-size: 24px;" />
            <span style="font-size: 14px;">请输入您要找回的手机号进行身份校验</span>
          </div>
          <div style="margin-top: 30px;">
            <el-form ref="form" :model="form" :rules="rules" label-width="140px" label-position="right">
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <el-form-item prop="phone" label="手机号：">
                    <el-input v-model="form.phone" style="width:100%;" placeholder="请输入手机号" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <el-form-item prop="newPassword" label="新密码：">
                    <el-input v-model="form.newPassword" style="width:100%;" type="password" placeholder="请输入新密码" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <el-form-item prop="confirmNewPassword" label="确认新密码：">
                    <el-input v-model="form.confirmNewPassword" style="width:100%;" type="password" placeholder="请输入确认新密码" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="80" type="flex" justify="center" align="middle">
                <el-col :span="16">
                  <el-form-item prop="smsCode" label="验证码：">
                    <el-input v-model="form.smsCode" style="margin-left:-1px !important;width: 71%;" type="number" placeholder="请输入验证码" />
                    <debounce-button v-if="showTime === false" :loading="loading.smsCode" style="padding: 10px 5px !important;vertical-align: middle;width: 92px;" @click="sendVerificationSms">
                      获取验证码
                    </debounce-button>
                    <el-button v-else style="padding: 9px 15px !important;vertical-align: middle;">
                      {{ time }}S后重发
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="80">
                <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
                  <el-button class="add-btn" @click="$router.back()">
                    返回登录
                  </el-button>
                  <debounce-button :loading="loading.submit" class="add-btn" type="primary" @click="commit">
                    下一步
                  </debounce-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-if="active === 1" class="find-pass-word-content">
          <div style="padding-top: 40px;text-align: center;">
            <i class="el-icon-success" style="color: #1989fa !important;font-size: 60px;" />
            <p style="font-size: 14px;font-weight: bold;padding: 20px 0px;">
              你的新登录密码设置成功！
            </p>
            <el-button class="add-btn" type="primary" @click="$router.back()">
              返回登陆
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cookie } from '@/assets/js/config'
import api from '@/assets/js/api'
import IcsPageLoginHeader from '@/components/page-login-header'
export default {
  components: { IcsPageLoginHeader },
  data () {
    return {
      projectTitle: '',
      logonBackgroundMap: '',
      changeUrl: '',
      stepActiveIndex: 0,
      active: 0,
      time: 300,
      remember: false,
      showTime: false,
      loading: {
        smsCode: false,
        submit: false
      },
      personalization: {},
      form: {
        phone: '',
        email: '',
        smsCode: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        confirmNewPassword: [
          { required: true, validator: this.validateNewPassAgain, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  created () {
    if (cookie.empAccount() !== undefined && cookie.empAccount() !== 'undefined') {
      this.form.account = cookie.empAccount()
      this.remember = true
    } else {
      this.remember = false
    }
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    sendVerificationSms () {
      if (!this.form.phone) {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        })
        return false
      }
      this.loading.smsCode = true
      const data = {
        mobile: this.form.phone,
        type: 3,
        password: ''
      }
      this.api.open.sendSmsCode(data).then(result => {
        if (result.data.success === true) {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.showTime = true
          this.timer = setInterval(() => {
            if (this.time === 0) {
              clearInterval(this.timer)
              this.time = 300
              this.showTime = false
            } else {
              this.time--
            }
          }, 1000)
        } else {
          this.$message.error(result.data.message)
        }
      }).finally(() => {
        this.loading.smsCode = false
      })
    },
    // 校验新密码
    validateNewPassAgain (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (this.form.newPassword && value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致、请重新输入！'))
      } else {
        callback()
      }
    },
    commit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          const data = {
            confirmNewPassword: this.form.confirmNewPassword,
            mobile: this.form.phone,
            newPassword: this.form.newPassword,
            smsCode: this.form.smsCode
          }
          this.api.open.forget(data).then(result => {
            if (result.data.success === true) {
              this.active = 1
              this.stepActiveIndex = 2
              this.loading.submit = false
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../assets/images/demo/login-big.png");
  background-size: 100% 100%;
}
.find-pass-word-inner {
  width: 840px;
  height: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -370px;
  margin-top: -245px;
  padding: 40px 30px 40px;
  background-color: rgba(0, 0, 0, 0.6);
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 30px;
    width: 100%;
    height: 380px;

    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
}

</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
